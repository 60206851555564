import React from "react";
import { ThemeContext } from "../../ThemeContext";

import "./DarkModeToggle.css";

const DarkModeToggle = () => {

    const { colorMode, setColorMode } = React.useContext(ThemeContext);

    return (
        <form>
            <input id="dark-mode" className="toggle" type="checkbox" name="Dark mode" role="switch" value="on"
                checked={colorMode === 'dark'}
                width="50px"
                onChange={ev => {
                    setColorMode(ev.target.checked ? 'dark' : 'light');
                }} 
            />
            <label htmlFor="dark-mode" className="sr">Dark Mode</label>
        </form>
    );
}

export default DarkModeToggle;