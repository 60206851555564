import * as React from "react"
import { Link } from "gatsby"

import { isBrowser } from "../../../utils"
import "./NavOptions.css"

const NavOptions = () => {
    
    const workPagePath = "/work";
    const contactPagePath = "/contact";
    const resourcesPagePath = "/resources";
    const aboutPagePath = "/about";

    let currentPath;
    
    if (isBrowser()){
      currentPath = window.location.pathname;
    }

    return (
      <div className="nav-options">
        <a>
          <Link
            className={`nav-item ${currentPath === workPagePath ? "highlight" : ""}`}
            to={workPagePath}
          >
            {"Work"}
          </Link>
        </a>

        <a>
          <Link
            to={contactPagePath}
            className={`nav-item ${currentPath === contactPagePath ? "highlight" : ""}`}
          >
            {"Contact"}
          </Link>
        </a>

        <a>
          <Link
            to={resourcesPagePath}
            className={`nav-item ${currentPath === resourcesPagePath ? "highlight" : ""}`}
          >
            {"Resources"}
          </Link>
        </a>

        <a>
          <Link
            className={`nav-item ${currentPath === aboutPagePath ? "highlight" : ""}`}
            to={aboutPagePath}
          >
            {"About"}
          </Link>
        </a>
      </div>
    )
}

export default NavOptions;