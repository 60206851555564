import * as React from "react"
import Logo from "../Logo"
import NavOptions from "../NavOptions/NavOptions"
import DarkModeToggle from "../DarkModeToggle/DarkModeToggle"

import "./HeaderDesktop.css"

const HeaderDesktop = () => {
    return (
      <div className="header-container">
        <Logo logoWidth="100px"/>
        <DarkModeToggle />
        <NavOptions />
      </div>
    )
}

export default HeaderDesktop;
