import React from 'react'
import DarkModeToggle from '../DarkModeToggle/DarkModeToggle';

import Logo from '../Logo';
import MobileMenu from '../MobileMenu/MobileMenu';
import './HeaderMobile.css'

const HeaderMobile = () => {
    return (
        <div className="mobile-header-container">
            <Logo logoWidth="70px"/>
            <div className="toggle-and-menu-container">
                <DarkModeToggle />
                <MobileMenu />
            </div>
        </div>
    );
};

export default HeaderMobile;
