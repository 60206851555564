import * as React from "react"
import LogoSVG from "../../assets/branding/logo-mstanoevich.svg";
import { ThemeContext } from "../ThemeContext";

const Logo = ({ logoWidth }) => {
    const { colorMode } = React.useContext(ThemeContext);

    if (!colorMode) {
        return null;
    }
    
    let logo = LogoSVG;
    let invert;

    if (colorMode==="dark") {
        invert = 1;
    } else {
        invert = 0;
    }

    return <a href='/'>
            <img src={logo} width={logoWidth} style={{filter: `invert(${invert})`}}/>
        </a>
};

export default Logo;
