import * as React from "react"

import HeaderDesktop from "./HeaderDesktop/HeaderDesktop"
import useViewport from "../../UseViewport";
import HeaderMobile from "./HeaderMobile/HeaderMobile";
import { isBrowser } from "../../utils";


const Header = () => {

  if(isBrowser()) {
    const { width } = useViewport();
    const breakpoint = 640;

    return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />
  }

  return <HeaderDesktop />
};

export default Header;
